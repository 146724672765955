<template>
  <div />
</template>

<script>
  import { deleteCookie, getElement, isLeadError, isServer, postMessage } from '@ocp-zmarta/zmarta-cl'
  import { mapActions, mapGetters } from 'vuex'
  import requests from '../../../../requests'
  import { metric } from '../../../../utils'

  export default {
    name: 'EventListener',
    data: () => ({
      updateHandler: null
    }),
    computed: {
      ...mapGetters('translations', ['getMyAccountUrl', 'getMyAccountOffersUrl']),
      ...mapGetters('form', ['getForm'])
    },
    mounted () {
      this.killUpdate()

      this.EVENT_BUS.$on('application:update', () => { this.triggerUpdate() })
      this.EVENT_BUS.$on('application:kill-update', () => { this.killUpdate() })
      this.EVENT_BUS.$on('application:finish', () => { this.triggerFinish() })
      this.EVENT_BUS.$on('application:go-to-my-account', () => { this.goToMyAccount() })
      this.EVENT_BUS.$on('application:auto-login-redirect', () => { this.autoLoginRedirect() })
      this.EVENT_BUS.$on('application:auto-login-post-link', () => { this.autoLoginPostLink() })
      this.EVENT_BUS.$on('storage:clear', (reload) => { this.clearStorage(reload) })
      this.EVENT_BUS.$on('api:error', ({ errorName = null, error = null } = {}) => { this.apiError(errorName, error) })
      this.EVENT_BUS.$on('api:customError', ({ code }) => { this.apiCustomError(code) })
      this.EVENT_BUS.$on('external:event', ({ name, event, timeout }) => { this.externalEvent({ name, event, timeout }) })
      this.EVENT_BUS.$on('iframe:height', () => { this.iframeHeightEvent() })

      window.addEventListener('resize', this.onResize)
      window.addEventListener('scroll', this.onScroll)
      this.onResize()
    },
    beforeDestroy () {
      this.EVENT_BUS.$off('application:update')
      this.EVENT_BUS.$off('application:kill-update')
      this.EVENT_BUS.$off('application:go-to-my-account')
      this.EVENT_BUS.$off('application:auto-login-redirect')
      this.EVENT_BUS.$off('application:auto-login-post-link')
      this.EVENT_BUS.$off('storage:clear')
      this.EVENT_BUS.$off('api:error')
      this.EVENT_BUS.$off('api:customError')
      this.EVENT_BUS.$off('external:event')
      this.EVENT_BUS.$off('iframe:height')

      window.removeEventListener('resize', this.onResize)
      window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
      ...mapActions('messages', ['setModalMessage']),
      ...mapActions('misc', ['setMisc']),
      ...mapActions('authentication', ['createOauth']),
      onResize () {
        const width = window.innerWidth ||
          document.documentElement?.clientWidth ||
          document.body?.clientWidth

        this.setMisc({ isMobile: (width <= 768) })
        this.setMisc({ isTablet: (width > 768 && width <= 992) })
        this.setMisc({ isDesktop: (width > 992) })
      },
      onScroll () {
        this.setMisc({ scroll: window.scrollY })
      },
      async clearStorage (reload = false) {
        if (isServer()) return true

        setTimeout(() => {
          window.localStorage.removeItem(`zmarta_form_${this.VERTICAL}`)
          window.localStorage.removeItem(`zmarta_tracking_${this.VERTICAL}`)
          window.sessionStorage.removeItem(`zmarta_form_${this.VERTICAL}`)
          window.sessionStorage.removeItem(`zmarta_session_${this.VERTICAL}`)
          window.sessionStorage.removeItem('zmarta_session')
          deleteCookie('zmarta_consumer_loan_values')
          deleteCookie('zmarta_sme_loan_values')
        }, 0)

        if (reload) {
          await requests.serviceAuth.oauth.reset({
            market: this.MARKET,
            vertical: this.VERTICAL
          })

          window.location.reload()
        }
      },
      triggerUpdate () {
        this.killUpdate()

        this.updateHandler = setTimeout(() => {
          if (this.CL) {
            this.$store.dispatch('cl/application/updateApplication')
          } else if (this.SME) {
            this.$store.dispatch('sme/application/updateApplication')
          }
        }, 2000)
      },
      triggerFinish () {
        if (this.CL) {
          this.$store.dispatch('cl/application/finishApplication')
        } else if (this.SME) {
          this.$store.dispatch('sme/application/finishApplication')
        }
      },
      killUpdate () {
        clearTimeout(this.updateHandler)
        this.updateHandler = null
      },
      async goToMyAccount () {
        metric('application', { action: 'form', status: 'end' })

        const url = this.getMyAccountUrl
        window.appNo = null

        await this.clearStorage()
        setTimeout(() => {
          window.open(url, '_parent')
        }, 1000)
      },
      async autoLoginRedirect () {
        metric('application', { action: 'form', status: 'end' })

        const resolved = await this.createOauth('uuid')

        if (!resolved) {
          await this.setModalMessage({
            group: 'api',
            name: 'AUTO_LOGIN_ERROR',
            show: true
          })

          return
        }

        const url = this.getMyAccountOffersUrl
        window.appNo = null

        await this.clearStorage()
        setTimeout(() => {
          window.location.href = url
        }, 1000)
      },
      async autoLoginPostLink () {
        metric('application', { action: 'form', status: 'end' })

        const url = this.getMyAccountOffersUrl
        window.appNo = null

        await this.clearStorage()
        setTimeout(() => {
          postMessage({
            name: 'zmarta:iframe:redirect',
            url
          })
        }, 1000)
      },
      async externalEvent ({ name, event, timeout = false }) {
        setTimeout(() => {
          console.info(`${name}: ${event}`)
          window.dispatchEvent(new CustomEvent(event))
        }, timeout ? 1000 : 0)
      },
      iframeHeightEvent () {
        if (!this.WHITE_LABEL) return

        const appElement = getElement('#cl-form .cl-form')
        if (!appElement) return

        postMessage({
          name: 'zmarta:iframe',
          height: appElement?.scrollHeight
        })
      },
      apiError (name, error) {
        const showError = !isLeadError(name)
        this.setModalMessage({ group: 'api', name, code: error?.data?.code, show: showError })
        if (showError && error) console.error(error)
      },
      apiCustomError (code) {
        this.setModalMessage({ group: 'customErrors', name: code, show: true })
      }
    }
  }
</script>
